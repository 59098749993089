import React from "react";
import Home from "./Home";
import FeatureHighlightSection from "./FeatureHighlightSection";
import FeatureSection from "./FeatureSection";
import StatisticsSection from "./StatisticsSection";
import PromotionSection from "./PromotionSection";
import FeatureHeader from "./FeatureHeader";
import StatisticsHeader from "./StatisticsHeader";
import ComparisonHeader from "./ComparisonHeader";
import ComparisonSection from "./ComparisonSection";
import FounderStory from "./FounderStory";

const MainSection = () => {
  return (
    <main>
      <Home />
      <FeatureHighlightSection />
      <PromotionSection />
      <ComparisonHeader />
      <ComparisonSection />
      <FeatureHeader />
      <FeatureSection />
      <StatisticsHeader />
      <StatisticsSection />
      <FounderStory />
    </main>
  );
};

export default MainSection;
