import React, { useState } from "react";
import {
  Youtube,
  ChevronDown,
  Lightbulb,
  ExternalLink,
  Play,
} from "lucide-react";

const COLORS = {
  primary: "#1E88E5",
  primaryLight: "#42A5F5",
  primaryDark: "#1565C0",
  primaryAlpha: {
    5: "rgba(30, 136, 229, 0.05)",
    10: "rgba(30, 136, 229, 0.1)",
    20: "rgba(30, 136, 229, 0.2)",
    50: "rgba(30, 136, 229, 0.5)",
  },
};

const TutorialDemoPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);

  const tutorials = [
    {
      id: 1,
      title: "Getting Started with BetMate",
      category: "Basics",
      duration: "3:30",
      youtubeUrl: "https://youtube.com/watch?v=example1",
      steps: [
        "Creating your BetMate account",
        "Setting up your profile",
        "Understanding MateCash and MateCoins",
        "Exploring the main dashboard",
      ],
      tips: [
        "Familiarize yourself with MateCash and MateCoins usage",
        "Complete your profile for a better experience",
        "Explore the dashboard to understand available features",
      ],
    },
    {
      id: 2,
      title: "Creating Your First Bet",
      category: "Bet Creation",
      duration: "4:00",
      youtubeUrl: "https://youtube.com/watch?v=example2",
      steps: [
        "Selecting a bet type",
        "Setting odds and stakes",
        "Adding conditions and details",
        "Publishing your bet to the community",
      ],
      tips: [
        "Start with smaller stakes while learning",
        "Set clear terms for your bet",
        "Review your bet details before publishing",
      ],
    },
    {
      id: 3,
      title: "How to Rebet on Existing Bets",
      category: "Advanced Betting",
      duration: "3:45",
      youtubeUrl: "https://youtube.com/watch?v=example3",
      steps: [
        "Browsing available bets",
        "Understanding rebetting rules",
        "Selecting a bet to rebet on",
        "Finalizing and placing your rebet",
      ],
      tips: [
        "Check the original bet's odds before rebetting",
        "Ensure the bet has available funds for rebetting",
        "Rebet on familiar categories for better confidence",
      ],
    },
    {
      id: 4,
      title: "Boosting Engagement with MateCoins",
      category: "Engagement",
      duration: "3:20",
      youtubeUrl: "https://youtube.com/watch?v=example4",
      steps: [
        "Using MateCoins to create activities",
        "Increasing followers through engagement",
        "Utilizing social features for visibility",
        "Converting engagement into MateCash rewards",
      ],
      tips: [
        "Post frequent updates to engage followers",
        "Use MateCoins strategically to maximize reach",
        "Interact with the community to build a following",
      ],
    },
    {
      id: 5,
      title: "Using AI-Powered Odds Suggestions",
      category: "Premium Features",
      duration: "4:30",
      youtubeUrl: "https://youtube.com/watch?v=example5",
      steps: [
        "Accessing AI suggestions in the premium tab",
        "Understanding suggested odds",
        "Applying AI insights to create or rebet",
        "Evaluating AI confidence scores",
      ],
      tips: [
        "Combine AI insights with personal research",
        "Monitor AI suggestions for high-confidence bets",
        "Use suggested odds to make more strategic bets",
      ],
    },
    {
      id: 6,
      title: "Withdrawing Your Winnings",
      category: "Account & Transactions",
      duration: "2:50",
      youtubeUrl: "https://youtube.com/watch?v=example6",
      steps: [
        "Accessing the withdrawal section",
        "Selecting a payment method",
        "Reviewing withdrawal limits",
        "Confirming your withdrawal request",
      ],
      tips: [
        "Ensure your account is verified before withdrawing",
        "Familiarize yourself with withdrawal limits",
        "Track your transactions in the account history",
      ],
    },
  ];

  const handleWatchVideo = (url) => {
    window.open(url, "_blank");
  };

  const generatePatternGradient = (isHovered) => ({
    backgroundImage: `
      radial-gradient(circle at center, ${COLORS.primaryAlpha[20]} 0%, transparent 60%),
      linear-gradient(45deg, ${COLORS.primaryAlpha[5]} 25%, transparent 25%),
      linear-gradient(-45deg, ${COLORS.primaryAlpha[5]} 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${COLORS.primaryAlpha[5]} 75%),
      linear-gradient(-45deg, transparent 75%, ${COLORS.primaryAlpha[5]} 75%)
    `,
    backgroundSize: "100% 100%, 20px 20px, 20px 20px, 20px 20px, 20px 20px",
    backgroundPosition: "0 0, 10px 0, 0 0, 0 0, 0 0",
    opacity: isHovered ? 0.8 : 0.4,
    transition: "opacity 0.3s ease",
  });

  return (
    <div style={{ backgroundColor: "#000" }} className="min-h-screen">
      <div className="container mx-auto px-4 py-24">
        <div className="text-center mb-16">
          <h1 className="text-xl font-bold text-white mb-4">
            Learn How to Use Our Platform
          </h1>
          <p className="text-red-500 italic">
            Videos are coming soon, and details are subject to changes.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
          {tutorials.map((tutorial) => (
            <div
              key={tutorial.id}
              className="group relative"
              onMouseEnter={() => setHoveredId(tutorial.id)}
              onMouseLeave={() => setHoveredId(null)}
            >
              <div
                className="absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur"
                style={{ backgroundColor: COLORS.primaryAlpha[50] }}
              />

              <div className="relative bg-black rounded-2xl border border-white/10 overflow-hidden h-full flex flex-col">
                <div className="p-4">
                  <div className="flex justify-between items-start mb-4">
                    <div className="flex items-start space-x-3">
                      <div
                        style={{ backgroundColor: COLORS.primaryAlpha[10] }}
                        className="p-2 rounded-lg"
                      >
                        <Youtube
                          style={{ color: COLORS.primary }}
                          className="w-5 h-5"
                        />
                      </div>
                      <div>
                        <span
                          style={{ color: COLORS.primary }}
                          className="text-sm font-medium mb-1 block"
                        >
                          {tutorial.category} • {tutorial.duration}
                        </span>
                        <h3 className="text-lg font-semibold text-white">
                          {tutorial.title}
                        </h3>
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        setExpandedSection(
                          expandedSection === tutorial.id ? null : tutorial.id
                        )
                      }
                      style={{ color: COLORS.primary }}
                      className="hover:opacity-80 transition-opacity duration-300 p-1.5"
                    >
                      <ChevronDown
                        className={`w-4 h-4 transition-transform duration-300 ${
                          expandedSection === tutorial.id ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                  </div>
                  <div
                    className="relative aspect-video rounded-lg overflow-hidden mb-3 cursor-pointer group/video"
                    onClick={() => handleWatchVideo(tutorial.youtubeUrl)}
                  >
                    <div
                      className="absolute inset-0 transition-opacity duration-300"
                      style={generatePatternGradient(hoveredId === tutorial.id)}
                    >
                      <div className="absolute inset-0 animate-pulse" />
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 bg-black/40 group-hover/video:bg-black/60">
                      <div
                        style={{ backgroundColor: COLORS.primary }}
                        className="flex items-center space-x-2 backdrop-blur-sm px-4 py-2 rounded-lg transform transition-all duration-300 group-hover/video:scale-110"
                      >
                        <Play className="h-4 w-4 text-white" />
                        <span className="text-white text-sm font-medium">
                          Watch Tutorial
                        </span>
                        <ExternalLink className="h-3 w-3 text-white" />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`grid transition-all duration-300 ${
                      expandedSection === tutorial.id
                        ? "grid-rows-[1fr]"
                        : "grid-rows-[0fr]"
                    }`}
                  >
                    <div className="overflow-hidden">
                      <div className="space-y-3 pt-2">
                        <div
                          style={{
                            backgroundColor: COLORS.primaryAlpha[5],
                            borderColor: COLORS.primaryAlpha[10],
                          }}
                          className="rounded-lg p-3 border"
                        >
                          <h4 className="text-white text-sm font-medium mb-2">
                            What You'll Learn
                          </h4>
                          <div className="space-y-2">
                            {tutorial.steps.map((step, index) => (
                              <div
                                key={index}
                                className="flex items-start space-x-2"
                              >
                                <div
                                  style={{
                                    backgroundColor: COLORS.primaryAlpha[10],
                                  }}
                                  className="w-5 h-5 flex items-center justify-center rounded-full flex-shrink-0"
                                >
                                  <span
                                    style={{ color: COLORS.primary }}
                                    className="text-xs"
                                  >
                                    {index + 1}
                                  </span>
                                </div>
                                <span className="text-gray-400 text-sm">
                                  {step}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          style={{
                            backgroundColor: COLORS.primaryAlpha[5],
                            borderColor: COLORS.primaryAlpha[10],
                          }}
                          className="rounded-lg p-3 border"
                        >
                          <h4 className="text-white text-sm font-medium mb-2">
                            Pro Tips
                          </h4>
                          <div className="space-y-2">
                            {tutorial.tips.map((tip, index) => (
                              <div
                                key={index}
                                className="flex items-start space-x-2"
                              >
                                <Lightbulb
                                  style={{ color: COLORS.primary }}
                                  className="w-4 h-4 flex-shrink-0"
                                />
                                <span className="text-gray-400 text-sm">
                                  {tip}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TutorialDemoPage;
