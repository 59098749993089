import React from "react";

const HighlightSection = () => {
  return (
    <section className="bg-black py-20 relative overflow-hidden">
      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="lg:pr-8">
            <h2 className="text-3xl md:text-4xl font-bold leading-tight">
              <span className="text-white">CONNECTING </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
                SPORTS ENTHUSIASTS
              </span>
              <span className="block text-gray-400 mt-1">THROUGH </span>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
                SOCIAL BETTING
              </span>
            </h2>
            <p className="mt-6 text-base md:text-lg text-gray-400">
              BetMate merges your love for sports with social interaction and
              innovative betting.
            </p>
          </div>
          <div className="relative h-[500px] w-full flex items-center justify-center">
            <div
              className="
                absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                w-[150%] h-[150%] bg-gradient-to-r from-blue-500/20 to-transparent
                blur-3xl pointer-events-none
                transform -rotate-12
              "
            />
            <div className="w-[80%] h-[80%] relative">
              <div
                className="
                  w-full h-full
                  transform -rotate-12 hover:rotate-0
                  transition-transform duration-500
                  group
                  relative
                "
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-transparent rounded-2xl" />
                <img
                  src="/high.PNG"
                  alt="BetMate Platform"
                  className="
                    w-full h-full object-contain rounded-2xl
                    border border-white/10
                    shadow-2xl shadow-blue-500/20
                  "
                />
                <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl -z-10" />
                <div className="absolute -inset-3 bg-gradient-to-r from-blue-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl blur-xl -z-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px]" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px]" />
      </div>
    </section>
  );
};

export default HighlightSection;
