import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, CheckCircle2, Loader2, Send } from "lucide-react";

const API_BASE_URL = "https://sea-lion-app-xxnd5.ondigitalocean.app";

const SuccessMessage = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="absolute inset-0 flex flex-col items-center justify-center bg-black/95 rounded-2xl p-8"
  >
    <motion.div
      initial={{ scale: 0, rotate: -180 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{
        type: "spring",
        stiffness: 200,
        damping: 20,
      }}
    >
      <CheckCircle2 size={56} className="text-emerald-500" strokeWidth={2.5} />
    </motion.div>

    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="text-center mt-6"
    >
      <h3 className="text-2xl font-bold text-white mb-2">Message Sent!</h3>
      <p className="text-gray-400 text-sm">
        Thank you for reaching out. We'll get back to you soon.
      </p>
    </motion.div>
  </motion.div>
);

const ContactUsModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      const response = await fetch(`${API_BASE_URL}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowSuccess(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
        setTimeout(() => {
          handleClose();
        }, 2500);
      } else {
        setError("Failed to send your message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending contact message:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setShowSuccess(false);
    }, 300);
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex justify-center items-center z-50">
      <motion.div
        className="bg-black border border-gray-800 text-white p-8 rounded-2xl shadow-2xl max-w-lg w-full relative overflow-hidden"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-300 focus:outline-none"
        >
          <X size={24} />
        </button>
        <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm text-gray-300 mb-1">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-black border border-gray-800 rounded-lg text-white 
                          focus:outline-none focus:border-[#1E88E5] focus:ring-1 focus:ring-[#1E88E5] 
                          transition-all duration-300"
                required
              />
            </div>
            <div>
              <label className="block text-sm text-gray-300 mb-1">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-black border border-gray-800 rounded-lg text-white 
                          focus:outline-none focus:border-[#1E88E5] focus:ring-1 focus:ring-[#1E88E5] 
                          transition-all duration-300"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm text-gray-300 mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-black border border-gray-800 rounded-lg text-white 
                          focus:outline-none focus:border-[#1E88E5] focus:ring-1 focus:ring-[#1E88E5] 
                          transition-all duration-300"
                required
              />
            </div>
            <div>
              <label className="block text-sm text-gray-300 mb-1">Phone</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-black border border-gray-800 rounded-lg text-white 
                          focus:outline-none focus:border-[#1E88E5] focus:ring-1 focus:ring-[#1E88E5] 
                          transition-all duration-300"
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-sm text-gray-300 mb-1">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-black border border-gray-800 rounded-lg text-white 
                        focus:outline-none focus:border-[#1E88E5] focus:ring-1 focus:ring-[#1E88E5] 
                        transition-all duration-300"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full py-3 bg-[#1E88E5] text-white rounded-lg font-semibold 
                     hover:bg-[#1976D2] transition-all duration-300 focus:outline-none 
                     focus:ring-2 focus:ring-[#1E88E5] focus:ring-offset-2 focus:ring-offset-black
                     flex items-center justify-center gap-2"
          >
            {isSubmitting ? (
              <>
                <Loader2 size={20} className="animate-spin" />
                <span>Sending...</span>
              </>
            ) : (
              <>
                <Send size={20} />
                <span>Send Message</span>
              </>
            )}
          </button>
        </form>
        {error && <p className="mt-4 text-sm text-red-500">{error}</p>}

        <AnimatePresence>
          {showSuccess && <SuccessMessage onClose={handleClose} />}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default ContactUsModal;
