import React, { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";

const faqData = [
  {
    question: "What is BetMate?",
    answer:
      "BetMate is a peer-to-peer social betting platform where users can create and join personalized betting markets, set their own odds, and engage with others in a unique, community-driven experience.",
  },
  {
    question: "What are the currencies used on BetMate?",
    answer:
      "BetMate uses two types of in-app currency: MateCash and MateCoins. MateCash is used for real betting activities, while MateCoins are earned through engagement and can be used for social and non-monetary betting interactions.",
  },
  {
    question: "Can I buy MateCoins?",
    answer:
      "Yes. You can purchase MateCoins to create activities that boost your followers. The more followers you gain, the more MateCash rewards you can earn.",
  },
  {
    question: "How does BetMate work?",
    answer:
      "BetMate allows users to create betting markets as 'bookies' or place bets on markets created by others. Users can set their own odds, explore different markets, and connect with others to place bets in a social environment.",
  },
  {
    question: "Is BetMate free to use?",
    answer:
      "Yes, BetMate is free to use. You can create an account and start betting with friends or join other markets. There may be optional features, like AI-generated odds suggestions, available through a subscription.",
  },
  {
    question: "How do I earn rewards on BetMate?",
    answer:
      "You can earn rewards by engaging in the community, creating betting markets, and contributing tips or predictions. BetMate recognizes active users by rewarding engagement and interaction on the platform.",
  },
  {
    question: "Can I act as both a bookie and a bettor?",
    answer:
      "Yes, on BetMate, users can create their own betting markets as bookies or join others as bettors, providing flexibility and freedom to engage in betting however they prefer.",
  },
  {
    question: "How do payouts work on BetMate?",
    answer:
      "Payouts depend on the outcome of bets in a market. When a user profits from a winning bet, BetMate takes a small percentage of the profit. Users who act as bookies only pay a percentage on profitable markets.",
  },
  {
    question: "What is the Rebet feature?",
    answer:
      "The Rebet feature allows users to follow and place wagers on existing bets created by others. If a user mirrors a bet, the original creator receives a bonus, creating a collaborative environment.",
  },
  {
    question:
      "What makes BetMate different from traditional betting platforms?",
    answer:
      "Unlike traditional betting houses, BetMate is user-centered, enabling users to set odds, create and join custom markets, and connect socially. BetMate prioritizes transparency, social interaction, and engagement rewards.",
  },
  {
    question: "Is BetMate available on mobile?",
    answer:
      "Yes, BetMate is designed as a mobile-first platform, making it accessible and convenient to use on the go.",
  },
  {
    question: "What payment methods does BetMate support?",
    answer:
      "BetMate supports widely-used payment methods, allowing for easy deposits and withdrawals. Payment methods vary by region to ensure accessibility for all users.",
  },
  {
    question: "How do I get started with my first bet?",
    answer:
      "To start, simply create an account, explore available betting markets, or create your own. You’ll also unlock a special reward when you create your first bet.",
  },
  {
    question: "Can I bet with my friends on BetMate?",
    answer:
      "Yes! BetMate makes it easy to create private betting circles with friends, so you can bet with people you know.",
  },
  {
    question: "How are odds determined on BetMate?",
    answer:
      "Users have the flexibility to set their own odds when creating betting markets. BetMate also offers an optional AI-generated odds suggestion feature for subscribers.",
  },
  {
    question:
      "What happens if there isn’t enough money in a betting market to cover my bet?",
    answer:
      "If a market lacks sufficient funds to cover potential payouts, your bet will not be accepted. You can either reduce your stake or find another market with a higher balance.",
  },
  {
    question: "How do I withdraw my winnings?",
    answer:
      "Winnings can be withdrawn directly through BetMate’s supported payment options. Simply navigate to the withdrawal section in your account settings.",
  },
  {
    question: "Does BetMate offer any support for responsible betting?",
    answer:
      "Yes, BetMate encourages responsible betting by allowing users to set personal limits and by promoting awareness around responsible gaming practices.",
  },
  {
    question:
      "What are the benefits of subscribing to BetMate’s premium features?",
    answer:
      "Subscribers gain access to additional tools, like AI-generated odds suggestions, and may receive exclusive rewards or enhanced community features.",
  },
  {
    question: "How do I contact BetMate support?",
    answer:
      "You can reach our support team directly through the app’s 'Help' section or contact us via email for any questions or assistance.",
  },
  {
    question: "Is BetMate safe and secure?",
    answer:
      "Yes, BetMate uses advanced security protocols to ensure all transactions and personal data are protected, creating a safe environment for our users.",
  },
];

const FaqItem = ({ question, answer, isOpen, toggle }) => {
  return (
    <div className="border-b border-white/10">
      <button
        className="w-full flex items-center justify-between py-5 text-left group focus:outline-none"
        onClick={toggle}
      >
        <div className="flex items-center space-x-3">
          <div
            className={`
              w-3 h-3 rounded-full transition-colors duration-300
              ${isOpen ? "bg-blue-500" : "bg-white/20"}
              group-hover:bg-blue-400
            `}
          ></div>
          <h3
            className={`
              text-xl font-medium transition-colors duration-300
              ${isOpen ? "text-blue-400" : "text-white"}
              group-hover:text-blue-400
            `}
          >
            {question}
          </h3>
        </div>
        <ChevronDown
          size={20}
          className={`
            text-blue-400 transition-transform duration-300
            ${isOpen ? "rotate-180" : "rotate-0"}
          `}
        />
      </button>
      <div
        className={`
          overflow-hidden transition-all duration-500
          ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}
        `}
      >
        <p className="pb-5 pl-6 text-gray-400 leading-relaxed">{answer}</p>
      </div>
    </div>
  );
};

const FaqSection = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <section className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black px-6 py-40">
      <div className="container mx-auto max-w-3xl">
        <h2
          className={`
            text-4xl font-extrabold text-center mb-12 text-white
            transform transition-all duration-700 delay-200
            ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }
          `}
        >
          Frequently Asked Questions
        </h2>

        <div
          className={`
            transform transition-all duration-700 delay-400
            ${
              mounted ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
            }
          `}
        >
          {faqData.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openFaqIndex === index}
              toggle={() =>
                setOpenFaqIndex(openFaqIndex === index ? null : index)
              }
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
