import React, { useState, useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronUp, ChevronDown, PauseCircle, PlayCircle } from "lucide-react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const words = ["Simple", "Secure", "Free"];

  const images = [
    "Image-03.png",
    "Image-04.png",
    "Image-05.png",
    "Image-06.png",
    "Image-07.png",
  ];
  const SLIDE_DURATION = 6000;

  useEffect(() => {
    let interval;
    if (isAnimating) {
      interval = setInterval(() => {
        handleNext();
      }, SLIDE_DURATION);
    }
    return () => clearInterval(interval);
  }, [isAnimating]);

  const handleNext = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black via-gray-900 to-black overflow-hidden">
      <main className="w-full min-h-screen pt-16">
        <div className="container mx-auto px-6 py-12">
          <div className="flex flex-col items-center lg:flex-row justify-between gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center lg:text-left lg:w-1/2"
            >
              <h1 className="text-5xl md:text-6xl font-extrabold mb-4">
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-white"
                >
                  Bet
                </motion.span>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="text-[#1E88E5]"
                >
                  Mate
                </motion.span>
              </h1>
              <h2 className="text-3xl md:text-4xl font-semibold mb-6 flex justify-center lg:justify-start">
                <span className="mr-2 text-white">We are</span>
                <span className="text-[#1E88E5]">
                  <Typewriter
                    words={words}
                    loop={0}
                    cursor
                    cursorStyle="_"
                    typeSpeed={100}
                    deleteSpeed={50}
                    delaySpeed={1500}
                  />
                </span>
              </h2>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="text-lg md:text-xl text-gray-300 mb-8"
              >
                Create, join, and personalize your bets with BetMate. Connect,
                predict, and earn just from your social impact while making
                every game more engaging.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="flex flex-col sm:flex-row items-center sm:justify-center lg:justify-start space-y-4 sm:space-y-0 sm:space-x-6"
              >
                <a
                  href="#"
                  className="relative group"
                  data-tooltip-id="apple-tooltip"
                >
                  <img
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    alt="Download on the Apple Store"
                    className="w-32 sm:w-36 transform transition-transform duration-300 group-hover:scale-105"
                  />
                </a>
                <a
                  href="#"
                  className="relative group"
                  data-tooltip-id="google-tooltip"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                    alt="Get it on Google Play"
                    className="w-32 sm:w-36 transform transition-transform duration-300 group-hover:scale-105"
                  />
                </a>
              </motion.div>
            </motion.div>

            <div className="lg:w-1/2 w-full">
              <div className="flex flex-col items-center gap-6">
                <div className="relative w-full max-w-sm mx-auto">
                  <div
                    className="absolute inset-0 rounded-3xl"
                    style={{
                      background:
                        "radial-gradient(circle at center, rgba(30,136,229,0.2) 0%, transparent 70%)",
                      filter: "blur(20px)",
                      transform: "translateY(20px) scale(0.9)",
                    }}
                  />
                  <div
                    className="relative rounded-3xl overflow-hidden backdrop-blur-sm"
                    style={{
                      boxShadow: `
                        0 25px 50px -12px rgba(30,136,229,0.25),
                        0 0 0 1px rgba(255,255,255,0.1),
                        0 0 30px rgba(30,136,229,0.1) inset
                      `,
                    }}
                    {...handlers}
                  >
                    <div
                      className="w-full h-full relative"
                      style={{ minHeight: "500px" }}
                    >
                      <AnimatePresence mode="wait" initial={false}>
                        <motion.img
                          key={currentImageIndex}
                          src={images[currentImageIndex]}
                          alt={`BetMate Preview ${currentImageIndex + 1}`}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 0.8,
                            ease: "easeInOut",
                          }}
                          className="absolute top-0 left-0 w-full h-full object-contain"
                        />
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="absolute -right-16 top-1/2 transform -translate-y-1/2 lg:flex flex-col space-y-4 hidden">
                    <button
                      onClick={handlePrev}
                      className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                      aria-label="Previous image"
                    >
                      <ChevronUp className="text-blue-400" size={24} />
                    </button>

                    <button
                      onClick={() => setIsAnimating(!isAnimating)}
                      className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                      data-tooltip-id="carousel-tooltip"
                      aria-label={isAnimating ? "Pause" : "Play"}
                    >
                      {isAnimating ? (
                        <PauseCircle className="text-blue-400" size={24} />
                      ) : (
                        <PlayCircle className="text-blue-400" size={24} />
                      )}
                    </button>

                    <button
                      onClick={handleNext}
                      className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                      aria-label="Next image"
                    >
                      <ChevronDown className="text-blue-400" size={24} />
                    </button>
                  </div>
                  <div className="absolute -left-8 top-1/2 transform -translate-y-1/2 lg:flex flex-col space-y-4 hidden">
                    {images.map((_, idx) => (
                      <motion.div
                        key={idx}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: idx * 0.1 }}
                        className="relative group"
                        onMouseEnter={() => setHoveredIndex(idx)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        <div
                          onClick={() => setCurrentImageIndex(idx)}
                          className={`
                            h-4 w-4 cursor-pointer rounded-full
                            transition-all duration-300
                            ${
                              currentImageIndex === idx
                                ? "bg-blue-500"
                                : "bg-gray-700 group-hover:bg-blue-400"
                            }
                          `}
                        />
                        {hoveredIndex === idx && (
                          <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            className="absolute left-6 top-1/2 -translate-y-1/2 bg-blue-500/10 backdrop-blur-sm px-2 py-1 rounded text-xs text-blue-400 whitespace-nowrap"
                          >
                            Screen {idx + 1}
                          </motion.div>
                        )}
                      </motion.div>
                    ))}
                  </div>
                  <div className="flex flex-col items-center mt-4 lg:hidden">
                    <div className="flex justify-center space-x-4">
                      <button
                        onClick={handlePrev}
                        className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                        aria-label="Previous image"
                      >
                        <ChevronUp className="text-blue-400" size={24} />
                      </button>

                      <button
                        onClick={() => setIsAnimating(!isAnimating)}
                        className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                        data-tooltip-id="carousel-tooltip"
                        aria-label={isAnimating ? "Pause" : "Play"}
                      >
                        {isAnimating ? (
                          <PauseCircle className="text-blue-400" size={24} />
                        ) : (
                          <PlayCircle className="text-blue-400" size={24} />
                        )}
                      </button>

                      <button
                        onClick={handleNext}
                        className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
                        aria-label="Next image"
                      >
                        <ChevronDown className="text-blue-400" size={24} />
                      </button>
                    </div>
                    <div className="flex justify-center mt-2 space-x-2">
                      {images.map((_, idx) => (
                        <div
                          key={idx}
                          onClick={() => setCurrentImageIndex(idx)}
                          className={`
                            h-2 w-2 cursor-pointer rounded-full
                            transition-all duration-300
                            ${
                              currentImageIndex === idx
                                ? "bg-blue-500"
                                : "bg-gray-700 hover:bg-blue-400"
                            }
                          `}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Tooltip
        id="apple-tooltip"
        place="top"
        content="Download on the Apple Store"
      />
      <Tooltip
        id="google-tooltip"
        place="top"
        content="Get it on Google Play"
      />
      <Tooltip
        id="carousel-tooltip"
        place="top"
        content={isAnimating ? "Pause" : "Play"}
      />
    </div>
  );
};

export default Home;
