import React from "react";
import { Quote, Sparkles } from "lucide-react";

const FounderStory = () => {
  return (
    <section className="bg-black py-24 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px] animate-pulse delay-150" />
        <div className="absolute top-1/3 left-1/4 w-1/3 h-1/3 bg-purple-500/5 blur-[90px] animate-pulse delay-300" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-5xl md:text-7xl font-bold relative inline-block hover:transform hover:scale-105 transition-all duration-500">
              <span className="text-white">THE </span>
              <div className="relative inline-block">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 animate-gradient">
                  VISION
                </span>
                <Sparkles className="absolute -top-8 -right-8 w-8 h-8 text-blue-500 animate-bounce" />
              </div>
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-8">
              <div className="p-8 rounded-2xl bg-gradient-to-br from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/10 transition-all duration-300">
                <p className="text-gray-300 text-lg leading-relaxed hover:text-white transition-colors duration-300">
                  BetMate was born from watching sports with friends, making
                  casual predictions, and realizing how disconnected traditional
                  betting had become from the social spirit of sports.
                </p>
              </div>
              <div className="relative transform hover:translate-x-2 hover:-translate-y-1 transition-all duration-300">
                <Quote className="absolute -top-4 -left-4 w-8 h-8 text-blue-500 opacity-20 group-hover:opacity-40 transition-opacity duration-300" />
                <div className="p-8 rounded-2xl bg-gradient-to-r from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 hover:border-blue-400/30 hover:shadow-lg hover:shadow-blue-500/10">
                  <p className="text-gray-300 italic text-lg hover:text-white transition-colors duration-300">
                    "The future of betting isn't just about odds and stakes –
                    it's about community, transparency, and shared passion for
                    sports."
                  </p>
                  <div className="mt-6 transform hover:translate-x-1 transition-transform duration-300">
                    <h4 className="text-xl font-bold">
                      <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400 hover:to-blue-300 transition-colors duration-300">
                        Edouard Kwizera
                      </span>
                    </h4>
                    <p className="text-blue-400 hover:text-blue-300 transition-colors duration-300">
                      Founder & CEO, BetMate
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-8 md:mt-16">
              <div className="p-8 rounded-2xl bg-gradient-to-bl from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 transform hover:scale-[1.02] hover:shadow-lg hover:shadow-blue-500/10 transition-all duration-300">
                <p className="text-gray-300 text-lg leading-relaxed hover:text-white transition-colors duration-300">
                  We're building a platform where every prediction strengthens
                  connections, where knowledge is shared freely, and where
                  success is celebrated together.
                </p>
              </div>

              <div className="p-8 rounded-2xl bg-gradient-to-r from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 transform hover:translate-x-2 hover:-translate-y-1 hover:shadow-lg hover:shadow-blue-500/10 transition-all duration-300">
                <p className="text-gray-300 text-lg leading-relaxed hover:text-white transition-colors duration-300">
                  Join us in creating a betting environment that's not just
                  about wins and losses, but about the shared excitement,
                  community insights, and the pure joy of sports that brings us
                  all together.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-16 relative">
            <div className="w-full h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent animate-pulse" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-32 h-px bg-gradient-to-r from-transparent via-blue-400/60 to-transparent blur-sm animate-pulse delay-75" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FounderStory;
