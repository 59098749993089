import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/navBar";
import Footer from "./components/Footer";
import ContactUsModal from "./components/ContactUsModal";
import MainSection from "./components/MainSection";
import FaqSection from "./components/FaqSection";
import HelpModal from "./components/HelpModal";
import TutorialDemoPage from "./components/TutorialDemoPage";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleOpenContactModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const handleCloseHelpModal = () => {
    setIsHelpModalOpen(false);
  };

  return (
    <Router>
      <NavBar onContactUsClick={handleOpenContactModal} />
      <Routes>
        <Route path="/" element={<MainSection />} />
        <Route path="/faq" element={<FaqSection />} />
        <Route path="/demo" element={<TutorialDemoPage />} />
      </Routes>
      <ContactUsModal isOpen={isModalOpen} onClose={handleCloseContactModal} />
      <HelpModal isOpen={isHelpModalOpen} onClose={handleCloseHelpModal} />
      <Footer onOpenHelpModal={handleOpenHelpModal} />
    </Router>
  );
}

export default App;
