import React from "react";
import { ChevronDown } from "lucide-react";

const StatisticsHeader = () => {
  return (
    <section className="bg-black pt-32 pb-16 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 right-1/4 w-1/2 h-1/2 bg-blue-600/5 blur-[100px]" />
        <div className="absolute bottom-0 left-1/4 w-1/2 h-1/2 bg-blue-600/5 blur-[100px]" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-white">EXPLORE OUR </span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              PLATFORM METRICS
            </span>
          </h2>
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 via-blue-600/10 to-blue-600/20 blur-lg" />
            <p className="text-gray-400 text-lg md:text-xl mb-12 max-w-2xl mx-auto relative">
              Discover real-time statistics that reflect our growth, community
              engagement, and the expanding influence of our platform. Stay
              updated and track our continuous success.
            </p>
          </div>
          <div className="relative h-16 mt-8">
            <div className="absolute left-1/2 transform -translate-x-1/2 animate-bounce">
              <div className="relative">
                <ChevronDown
                  className="w-8 h-8 text-blue-600 opacity-20 absolute top-0 left-0"
                  style={{ animation: "pulse 2s infinite" }}
                />
                <ChevronDown
                  className="w-8 h-8 text-blue-500 opacity-40 absolute top-1 left-0"
                  style={{ animation: "pulse 2s infinite 0.5s" }}
                />
                <ChevronDown
                  className="w-8 h-8 text-blue-400 opacity-60 absolute top-2 left-0"
                  style={{ animation: "pulse 2s infinite 1s" }}
                />
                <ChevronDown
                  className="w-8 h-8 text-white opacity-80"
                  style={{ animation: "pulse 2s infinite 1.5s" }}
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-600/50 to-transparent" />
        </div>
      </div>
    </section>
  );
};

export default StatisticsHeader;
