import React, { useState } from "react";
import {
  HelpCircle,
  Facebook,
  Twitter,
  Instagram as InstagramIcon,
} from "lucide-react";

const COLORS = {
  primary: "#1E88E5",
  primaryHover: "#1976D2",
  primaryAlpha: {
    10: "rgba(30, 136, 229, 0.1)",
    20: "rgba(30, 136, 229, 0.2)",
    50: "rgba(30, 136, 229, 0.5)",
  },
};

const Footer = ({ onOpenHelpModal }) => {
  const [isHelpHovered, setIsHelpHovered] = useState(false);
  const [hoveredSocial, setHoveredSocial] = useState(null);

  const socialLinks = [
    {
      Icon: Facebook,
      href: "https://facebook.com",
      label: "Facebook",
      hoverColor: "#1877F2",
    },
    {
      Icon: Twitter,
      href: "https://twitter.com",
      label: "Twitter",
      hoverColor: "#1DA1F2",
    },
    {
      Icon: InstagramIcon,
      href: "https://instagram.com",
      label: "Instagram",
      hoverColor: COLORS.primary,
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slowBounce {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-8px);
            }
          }
          .animate-slow-bounce {
            animation: slowBounce 3s infinite ease-in-out;
          }
        `}
      </style>
      <button
        style={{
          backgroundColor: isHelpHovered ? COLORS.primary : "transparent",
          borderColor: COLORS.primary,
          boxShadow: isHelpHovered
            ? `0 8px 24px ${COLORS.primaryAlpha[20]}`
            : "none",
        }}
        className={`
          fixed bottom-8 right-24 px-4 py-2 rounded-full 
          flex items-center space-x-2 border-2
          transition-all duration-300
          animate-slow-bounce
          hover:animate-none
          ${isHelpHovered ? "text-white" : "text-[#1E88E5]"}
        `}
        onMouseEnter={() => setIsHelpHovered(true)}
        onMouseLeave={() => setIsHelpHovered(false)}
        onClick={onOpenHelpModal}
      >
        <HelpCircle
          className={`transform transition-transform duration-300 ${
            isHelpHovered ? "rotate-12" : ""
          }`}
          size={20}
        />
        <span className="relative font-semibold text-sm">Need Help?</span>
      </button>

      <footer className="bg-black w-full border-t border-[#1E88E5]/10">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 py-8">
          <div className="text-sm text-gray-400 transition-all duration-300 cursor-default mb-4 md:mb-0 hover:text-gray-300">
            © 2024 BetMate. All rights reserved.
          </div>

          <div className="flex space-x-6 mb-4 md:mb-0">
            {socialLinks.map(({ Icon, href, label, hoverColor }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label}
                className="group relative p-2"
                onMouseEnter={() => setHoveredSocial(label)}
                onMouseLeave={() => setHoveredSocial(null)}
              >
                <Icon
                  className="transition-all duration-300 transform"
                  style={{
                    color: hoveredSocial === label ? hoverColor : "#9CA3AF",
                    transform:
                      hoveredSocial === label ? "scale(1.2)" : "scale(1)",
                  }}
                  size={24}
                />
                <span
                  className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                    bg-black/90 backdrop-blur-sm px-2 py-1 rounded text-xs
                    transition-all duration-300 border border-[#1E88E5]/20"
                  style={{
                    color: COLORS.primary,
                    opacity: hoveredSocial === label ? 1 : 0,
                    transform: `translate(-50%, ${
                      hoveredSocial === label ? "0" : "4px"
                    })`,
                  }}
                >
                  {label}
                </span>
              </a>
            ))}
          </div>

          <div className="flex space-x-8 text-sm">
            {[
              { href: "/privacy-policy", text: "Privacy Policy" },
              { href: "/terms-of-service", text: "Terms of Service" },
            ].map(({ href, text }) => (
              <a
                key={text}
                href={href}
                className="relative text-gray-400 hover:text-[#1E88E5] transition-all duration-300 group"
              >
                {text}
                <span
                  className="absolute -bottom-1 left-0 w-full h-0.5 transform scale-x-0 
                    group-hover:scale-x-100 transition-transform duration-300 origin-left"
                  style={{ backgroundColor: COLORS.primary }}
                />
              </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
