import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, Send, Loader2, CheckCircle } from "lucide-react";

const API_BASE_URL = "https://sea-lion-app-xxnd5.ondigitalocean.app";

const SuccessMessage = ({ onClose }) => (
  <motion.div
    initial={{ scale: 0.9, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.9, opacity: 0 }}
    className="absolute inset-0 flex flex-col items-center justify-center bg-black/95 rounded-2xl p-6 text-center"
  >
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
    >
      <CheckCircle size={48} className="text-green-500 mb-4" />
    </motion.div>
    <motion.h3
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
      className="text-xl font-semibold text-white mb-2"
    >
      Thank You!
    </motion.h3>
    <motion.p
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.4 }}
      className="text-gray-400 mb-6"
    >
      Your message has been sent successfully. We'll get back to you soon!
    </motion.p>
    <motion.button
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.5 }}
      onClick={onClose}
      className="px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium transition-colors duration-200"
    >
      Close
    </motion.button>
  </motion.div>
);

const HelpModal = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      const response = await fetch(`${API_BASE_URL}/api/help`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({ name: "", email: "", message: "" });
        setShowSuccess(true);
        setTimeout(() => {
          onClose();
          setShowSuccess(false);
        }, 3000);
      } else {
        setError("Failed to submit the form. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting the help request:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-end justify-end p-8"
      onClick={(e) => e.target === e.currentTarget && handleClose()}
    >
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 20, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="relative w-full max-w-sm bg-black border border-white/10 rounded-2xl shadow-xl"
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-white">Need Help?</h2>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-white/10 rounded-full transition-colors duration-200"
            >
              <X size={20} className="text-gray-400 hover:text-white" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm text-gray-400 mb-1.5">
                Your Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2.5 rounded-lg
                  bg-white/5 border border-white/10
                  text-white placeholder-gray-500
                  focus:outline-none focus:border-blue-500
                  transition-colors duration-200"
                required
              />
            </div>

            <div>
              <label className="block text-sm text-gray-400 mb-1.5">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2.5 rounded-lg
                  bg-white/5 border border-white/10
                  text-white placeholder-gray-500
                  focus:outline-none focus:border-blue-500
                  transition-colors duration-200"
                required
              />
            </div>

            <div>
              <label className="block text-sm text-gray-400 mb-1.5">
                How can we help you?
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-4 py-2.5 rounded-lg
                  bg-white/5 border border-white/10
                  text-white placeholder-gray-500
                  focus:outline-none focus:border-blue-500
                  transition-colors duration-200
                  resize-none"
                rows="4"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full py-2.5 px-4
                bg-blue-500 hover:bg-blue-600 active:bg-blue-700
                disabled:bg-blue-500/50 disabled:cursor-not-allowed
                text-white rounded-lg font-medium
                transition-all duration-200
                flex items-center justify-center space-x-2"
            >
              {isSubmitting ? (
                <>
                  <Loader2 size={18} className="animate-spin" />
                  <span>Sending...</span>
                </>
              ) : (
                <>
                  <Send size={18} />
                  <span>Send Message</span>
                </>
              )}
            </button>
          </form>

          {error && <p className="mt-4 text-sm text-red-500">{error}</p>}
        </div>

        <AnimatePresence>
          {showSuccess && <SuccessMessage onClose={handleClose} />}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default HelpModal;
